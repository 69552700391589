import React from 'react'

import Layout from '../components/layout'
import MyHelm from '../components/myhelm'
import Abbr from '../components/abbr'
import Products from '../components/products/products'

import {Check2} from 'react-bootstrap-icons'
import { Table } from 'react-bootstrap'

export default function IndexPage() {

  let title = "Home"
  let description = "Grow your business with a website powered by Telafast. Tell your friends, Telafast!"

  return (
    <Layout>
      <div className="d-flex justify-content-center pt-5">
      <MyHelm title={title} description={description} />
        <Table striped bordered style={{width: "75vw", textAlign: "right"}} className="border-laccent">
          <thead>
            <tr>
              <th className="text-start">Includes</th>
              <th>Basic</th>
              <th>Premium</th>
              <th>Ecommerce</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-start">Website Design</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Free Static Hosting</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">1yr Domain Registration</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Mobile Responsive</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Consultation</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Setup Basic <Abbr title="Search Engine Optimization" words={[0,1,2]}/></td>
              <td>-</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Content Management System</td>
              <td>-</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Google Business Setup</td>
              <td>-</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Facebook Page Setup</td>
              <td>-</td>
              <td><Check2 /></td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Ecommerce (25 products)</td>
              <td>-</td>
              <td>-</td>
              <td><Check2 /></td>
            </tr>
            <tr>
              <td className="text-start">Price</td>
              <td>600$</td>
              <td>2000$</td>
              <td>5000$</td>
            </tr>
            <Products/>
          </tbody>
        </Table>
      </div>
      <div className="mx-auto px-5">
        <h5>Website design and development fulfilled to customer specification. Once purchased you will be contacted via email.</h5>
      </div>
    </Layout>
  )
}