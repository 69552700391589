import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import SiteBasic from './SiteBasic'

const Products = () => {
    return (
        <StaticQuery 
            query={graphql`
                query ProductPrices {
                    prices: allStripePrice(
                        filter: { active: { eq: true } }
                        sort: { fields: [unit_amount] }
                    ) {
                        edges {
                            node {
                                id
                                active
                                currency
                                unit_amount
                                product {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            `}
            render={({ prices }) => {
                // Group prices by product
                const products = {}
                for (const { node: price } of prices.edges) {
                  const product = price.product
                  if (!products[product.id]) {
                    products[product.id] = product
                    products[product.id].prices = []
                  }
                  products[product.id].prices.push(price)
                }
                return (
                  <tr className="align-middle">
                    <td id="SiteBasic">
                      Websites take 1 week to 1 month to build (or more) depending on complexity and demand.
                    </td>
                    {Object.keys(products).map(key => (
                      <SiteBasic key={products[key].id} product={products[key]} />
                    ))}
                  </tr>
                )
              }}
        />
    )
}

export default Products