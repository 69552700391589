import React, { useState } from 'react'

import getStripe from '../getstripe'

  const formatPrice = (amount, currency) => {
    let price = (amount / 100).toFixed(2)
    let numberFormat = new Intl.NumberFormat(["en-US"], {
      style: "currency",
      currency: currency,
      currencyDisplay: "symbol",
    })
    return numberFormat.format(price)
  }

  const SiteBasic = ({ product }) => {
    const [loading, setLoading] = useState(false)


    const handleSubmit = async event => {
      event.preventDefault()
      setLoading(true)
      const price = new FormData(event.target).get("priceSelect")
      const stripe = await getStripe()
      const { error } = await stripe.redirectToCheckout({
        mode: "payment",
        lineItems: [{ price, quantity: 1 }],
        successUrl: `${window.location.origin}/success/`,
        cancelUrl: `${window.location.origin}/`,
      })
      if (error) {
        console.warn("Error:", error)
        setLoading(false)
      }
    }

    return (
      <td className="text-center SiteBasic">
        <div className="">
          <form onSubmit={handleSubmit}>
            <select name="priceSelect" className="d-none">
              {product.prices.map(price => (
                <option key={price.id} value={price.id}>
                  {formatPrice(price.unit_amount, price.currency)}
                </option>
              ))}
            </select>
            <button
              disabled={loading}
              className="btn btn-brand w-100"
              type="submit"
            >
              {!loading ? "Buy" : <div className="spinner-border text-lshade p-0 m-0" role="status" style={{width: "1.5rem", height: "1.5rem"}}></div>}
            </button>
          </form>
        </div>
      </td>
    )
  }

export default SiteBasic